import { useMemo } from 'react'
import styled from 'styled-components'
import { Text, Flex, CardBody, CardFooter, Button, AddIcon, Heading, CardHeader, LogoImg } from '@pancakeswap/uikit'
import Link from 'next/link'
import { useWeb3React } from '@pancakeswap/wagmi'
import { useTranslation } from '@pancakeswap/localization'
import { logoConfig } from 'components/Menu/config/config'
import { ListingCard, ListingsCardBody, ListingsCardHeader, ListingsCardTitle } from './styles'
import { AdCard } from './AdCard'
import { useAdDate } from './useAdData'



const Body = styled(ListingsCardBody)`
  padding: 16px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export default function ListingsCard() {
  const { account } = useWeb3React()
  const { t } = useTranslation()

  const { data } = useAdDate()

  if (data?.code || !data?.data || !data?.data?.focusPos) return (<></>)

  return (
    <ListingCard>
      <ListingsCardHeader>
        <ListingsCardTitle>
          <LogoImg src={logoConfig.logoSrc} width={40} height={40} />
          <Heading ml='9px'>{t('New listings')}</Heading>
        </ListingsCardTitle>
      </ListingsCardHeader>
      <ListingsCardBody>
        <AdCard data={data} />
      </ListingsCardBody>
      <CardFooter style={{ textAlign: 'start', padding: '8px 16px 16px 16px' }}>
        <Text fontSize="12px" color='text99'> {t('The above content is for inquiry and understanding only and is not intended as investment advice. Cryptocurrency transactions fluctuate greatly, please control the risks yourself. Profits and losses have nothing to do with this page.')}</Text>
      </CardFooter>
    </ListingCard>
  )
}
