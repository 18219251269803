import { useTranslation } from '@pancakeswap/localization'
import { AutoColumn } from 'components/Layout/Column'
import { useState } from 'react'
import { TopCard } from './TopCard'
import { AdList } from './AdList'
import { ADDataItemType, AdFocusType } from './types'



export function AdCard({ data }) {
  const { t } = useTranslation()
  const [active, setActive] = useState(0)
  return (
    <AutoColumn gap="0px">
      <TopCard data={(data?.data?.list?.[active]) as AdFocusType} />
      <AdList data={(data?.data?.list) as ADDataItemType[]} active={active} setActive={setActive} />
    </AutoColumn>
  )
}
