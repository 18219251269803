import { useRequest, useLocalStorageState, useMount } from "ahooks";
import axios from "axios";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { appearAnimation, useIsomorphicEffect } from "@pancakeswap/uikit";
import { useState } from "react";
import { useAdSwiperDate } from "./useAdSwiperData";




// const ADList: ADDataItem[] =
//   [
//     // {
//     //   "id": 19,
//     //   "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner/banner4.jpeg",
//     //   "redirectUrl": "https://www.financeboss.org/",
//     //   "status": 1,
//     //   "top": 5,
//     //   "createTime": 1663238511000,
//     //   "expiredTime": "2025-01-01 00:00:00"
//     // },
//     {
//       "id": 20,
//       "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner/banner12.jpeg",
//       "redirectUrl": "https://t.me/HappyFarm_CN",
//       "top": 9,
//       "createTime": 1663238511000,
//       "expiredTime": "2025-01-01 00:00:00"
//     },
//     {
//       "id": 20,
//       "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner/banner11.jpeg",
//       "redirectUrl": "https://t.me/sun_godde",
//       "top": 8,
//       "createTime": 1663238511000,
//       "expiredTime": "2025-01-01 00:00:00"
//     },

//     // {
//     //   "id": 20,
//     //   "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner/banner9.jpeg",
//     //   "redirectUrl": "https://t.me/MBT_CN",
//     //   "status": 1,
//     //   "top": 6,
//     //   "createTime": 1663238511000,
//     //   "expiredTime": "2025-01-01 00:00:00"
//     // },
//     {
//       "id": 19,
//       "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner/banner6.jpeg",
//       "redirectUrl": "https://t.me/bossbitswap",
//       "status": 1,
//       "top": 5,
//       "createTime": 1663238511000,
//       "expiredTime": "2025-01-01 00:00:00"
//     },
//     // {
//     //   "id": 20,
//     //   "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner_2.jpeg",
//     //   "redirectUrl": "https://t.me/nalanotaliona",
//     //   "status": 1,
//     //   "top": 4,
//     //   "createTime": 1663238511000,
//     //   "expiredTime": "2025-01-01 00:00:00"
//     // },
//     // {
//     //   "id": 21,
//     //   "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner_3.jpeg",
//     //   "redirectUrl": "https://t.me/dnAlpha",
//     //   "status": 1,
//     //   "top": 6,
//     //   "createTime": 1663238511000,
//     //   "expiredTime": "2025-01-01 00:00:00"
//     // },
//     // {
//     //   "id": 23,
//     //   "imageUrl": "https://d1l7ift7wyu6at.cloudfront.net/images/banner/banner5.jpeg",
//     //   "redirectUrl": "https://t.me/bossbitswap",
//     //   "status": 1,
//     //   "top": 6,
//     //   "createTime": 1663238511000,
//     //   "expiredTime": "2024-03-31 00:00:00"
//     // },
//     {
//       "id": 22,
//       "imageUrl": "https://oss.bitchain.biz/box-data/239e35082a3d787b1b6ae5064deeea3f.png",
//       "redirectUrl": "https://www.bnbchain.org/en/blog/daus-incentive-program-december-results",
//       "status": 1,
//       "top": 2,
//       "createTime": 1663238511000,
//       "expiredTime": "2025-01-01 00:00:00"
//     },

//   ]
//   ;
// 过期时间 1 天
// const expireTime = 1000 * 60 * 60 * 24;

const expireTime = 1000 * 60 * 10; // 10分钟


const BannerPlaceHolder = styled.div`
  position: relative;
  // width: 1112px;
  max-width: 100vw;
  margin-top: 0px;
`;

const StyledSwiper = styled(Swiper)`
  position: relative;
  overflow: visible;
  opacity: 1;
  animation: ${appearAnimation} 0.3s ease-in-out 0.7s forwards;
  .swiper-pagination {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 108px;
    bottom: 12px;
  }
  .swiper-pagination-bullet {
    background-color: white;
    flex-basis: 12px;
    height: 1px;
    margin: 0 !important;
    border-radius: 0px;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

export const ADSwiper: React.FC = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  // const [adList, setAdList] = useLocalStorageState<ADDataItem[]>("SwapADList", {
  //   defaultValue: ADList,
  // });
  // const [adTime, setAdTime] = useLocalStorageState<number>("SwapADTime", {
  //   defaultValue: 0,
  // });
  const { data } = useAdSwiperDate()

  // const sortedADList = ADList.sort((a, b) => {
  //   return b.top - a.top;
  // });

  // const loadbanner = () => {
  //   // 将ADList里面的数据按照权重从大到小排序，然后取前三个，如果不足三个，就取全部。
  //   // 这样可以保证每次刷新的时候，都是按照权重从大到小的顺序展示广告。
  //   const bannerSorted = sortedADList.slice(0, 6);
  //   setAdList(bannerSorted);
  //   setAdTime(Date.now());
  // }

  // useMount(() => {
  //   if (Date.now() - adTime > expireTime) {
  //     // run();
  //     // loadbanner();
  //   }
  // });
  useIsomorphicEffect(() => {
    if (data && swiperRef) {
      if (data?.length > 1 && !swiperRef.autoplay?.running) {
        swiperRef.autoplay?.start();
      } else if (data?.length <= 1 && swiperRef.autoplay?.running) {
        swiperRef.autoplay?.stop();
      }
    }
  }, [data, swiperRef]);
  if (!data || data.length === 0) return (<></>)
  return (
    <BannerPlaceHolder>
      <StyledSwiper
        onSwiper={setSwiperRef}
        modules={[Autoplay, Pagination, EffectFade]}
        spaceBetween={0}
        observer
        slidesPerView={1}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        speed={500}
        autoplay={{ delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false }}
        loop
        pagination={{ clickable: true }}
      >
        {data.map((item, index) => {
          const childKey = `Banner${index}`;
          return (
            <SwiperSlide key={childKey}>
              {item.redirectUrl ? (
                <a href={item.redirectUrl} style={{ display: "block", width: "100%" }} target="_blank" rel="noreferrer">
                  <img src={item.imageUrl} alt="" style={{ width: "100%" }} />
                </a>
              ) : (
                <img src={item.imageUrl} alt="" style={{ width: "100%" }} />
              )}
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </BannerPlaceHolder>
  );
};


