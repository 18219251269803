import { useRequest } from 'ahooks'
import axios from 'axios'

export const useAdDate = () => {
  const { loading, data, refresh } = useRequest(
    () => {
      return axios.get(`${process.env.NEXT_PUBLIC_ADAPI}/api/doge/focus/pos?pl=bitchain`)
    },
    {
      cacheKey: `/api/doge/focus/pos?pl=bitchain`,
      debounceWait: 300,
      retryCount: 3,
      refreshDeps: [],
    },
  )
  return { loading, data: data?.data, refresh }
}
