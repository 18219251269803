import { Text, Heading, CopyAddress, CopyButton, Tag, Row, TelegramIcon, TelegramFilledIcon, useToast, Flex } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { AutoColumn } from 'components/Layout/Column'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { addressEllipsis } from 'utils'
import styled from 'styled-components'
import { Dispatch, SetStateAction } from 'react'
import { AdItem } from './AdItem'
import { ADDataItemType, defaultADDataItem } from './types'


const testADDataItem = {
  id: 2,
  address: "0x55d398326f99059fF775485246999027B3197955",
  imageUrl: "https://d1l7ift7wyu6at.cloudfront.net/images/main/logoicon.png",
};

type ADDataItem = typeof testADDataItem;

// const ADList: ADDataItem[] =
//   [

//     {
//       id: 2,
//       address: "0x55d398326f99059fF775485246999027B3197955",
//       imageUrl: "https://d1l7ift7wyu6at.cloudfront.net/images/main/logoicon.png",
//     },
//     {
//       id: 2,
//       address: "0x55d398326f99059fF775485246999027B3197955",
//       imageUrl: "https://d1l7ift7wyu6at.cloudfront.net/images/main/logoicon.png",
//     },
//     {
//       id: 2,
//       address: "0x55d398326f99059fF775485246999027B3197955",
//       imageUrl: "https://d1l7ift7wyu6at.cloudfront.net/images/main/logoicon.png",
//     }

//   ]

const Container = styled(Flex)`
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4px;
  padding:  16px 0;
`



export function AdList({ data, active, setActive }: { data: ADDataItemType[], active: number, setActive: Dispatch<SetStateAction<number>> }) {
  const { t } = useTranslation()
  const maxItem = 12
  const newData = data
  Array.from(Array(maxItem - data.length)).forEach(item => {
    newData.push(defaultADDataItem)
  })
  return (
    <Container>
      {newData.map((item, index) => (<AdItem isActive={active === index} data={item} onClick={() => !!item.contract && setActive(index)} />))}
    </Container>
  )
}
