import { Text, Heading, CopyAddress, CopyButton, Tag, Row, TelegramIcon, TelegramFilledIcon, useToast, XFilledIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { AutoColumn } from 'components/Layout/Column'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { addressEllipsis } from 'utils'
import { AdFocusType } from './types'



export function TopCard({ data }: { data: AdFocusType }) {
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  return (
    <AutoColumn gap="8px">
      <RowBetween >
        <Text fontSize="14px" lineHeight="20px" bold>{data?.coinName}</Text>
        <RowFixed style={{ position: 'relative' }}>
          <Text color="text" fontSize="12px" textTransform="lowercase" mr='4px' >
            {t('Address')}{' '}{addressEllipsis(data?.contract)}
          </Text>
          <CopyButton width="16px" color='text' text={data?.contract} tooltipMessage={t('Copied')} tooltipTop={-40} tooltipFontSize={12} /></RowFixed>
      </RowBetween>
      <Text fontSize="12px" lineHeight='14px' color='text'> {data?.coinDes}</Text>
      <Row gap="8px" alignItems="flex-start">
        {data?.twitter ? <Tag style={{ position: 'relative' }} variant="text" scale='sm' outline startIcon={<XFilledIcon width="16px" />} endIcon={<CopyButton width="16px" color='text' text={data?.twitter} tooltipMessage={t('Copied')} tooltipTop={-40} tooltipFontSize={12} />} >
          @{data?.twitter.substring(data?.twitter.lastIndexOf('/') + 1, data?.twitter.length).substring(0, 10)}
        </Tag> : <></>}
        {data?.telegram ? <Tag style={{ position: 'relative' }} variant="text" scale='sm' outline startIcon={<TelegramFilledIcon width="16px" />} endIcon={<CopyButton width="16px" color='text' text={data?.telegram} tooltipMessage={t('Copied')} tooltipTop={-40} tooltipFontSize={12} />} >
          @{data?.telegram.substring(data?.telegram.lastIndexOf('/') + 1, data?.telegram.length).substring(0, 10)}
        </Tag> : <></>}

        {/* <Tag variant="text" scale='sm' outline startIcon={<TelegramFilledIcon width="16px" />} endIcon={<CopyButton width="16px" color='text' text={address} tooltipMessage={t('Copied')} tooltipTop={-40} onClick={() => {
          toastSuccess(t('Copied'), <></>)
        }} />} >
          12312312
        </Tag> */}
      </Row>
    </AutoColumn>
  )
}
