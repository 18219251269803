import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 12 12" {...props}>
      <path d="M8.87559 1.48047H10.409L7.05896 5.30933L11 10.5195H7.9142L5.49729 7.35958L2.73179 10.5195H1.19747L4.78065 6.42415L1 1.48047H4.16414L6.34882 4.3688L8.87559 1.48047ZM8.33741 9.60173H9.18709L3.70245 2.35007H2.79066L8.33741 9.60173Z" />
    </Svg>

  );
};

export default Icon;
