import { Card, Flex, mobileFooterHeight } from "@pancakeswap/uikit";
import React from "react";
import styled from "styled-components";


export const ListingsCardHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  display: flex;
  padding: 12px 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    background: ${({ background }) => background || "transparent"};
  }
`;

export const ListingsCardTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ListingsCardBody = styled(Flex)`
  flex-direction: column;
  padding: 12px 16px 0 16px;
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    
  }
`;

export const ListingCard = styled(Card)`
  
  border-radius: 18px;
  max-width: 328px;
  width: 100 %;
  z-index: 1;
  margin: 0 auto;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 0 40px 0;
    
  }
  

`

