import { useRequest } from "ahooks";
import axios from "axios";

const testADDataItem = {
  id: 3,
  title: "123123",
  des: "123123",
  redirectUrl: "https://www.google.com",
  imageUrl: "https://www.ucbitex.com/assets/intro_adorn_1-CN3UeioB.png",
  weights: 3,
  stime: 1663238511000,
  etime: 1663238511000,
  dataStatus: 1,
  platform: "bitchain",
  createTime: 1663238511000,
  updateTime: 1663238511000,
};

export type ADDataItem = typeof testADDataItem;
export const useAdSwiperDate = () => {
  const { loading, data, refresh } = useRequest(
    () => {
      return axios.get(`${process.env.NEXT_PUBLIC_ADAPI}/api/doge/focus/pic?pl=bitchain`);
    },
    {
      cacheKey: `/api/doge/focus/pic?pl=bitchain`,
      debounceWait: 300,
      retryCount: 3,
      refreshDeps: [],
    }
  );
  return { loading, data: data?.data?.data?.list as ADDataItem[], refresh };
};
