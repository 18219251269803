export const defaultADDataItem = {
  id: null,
  contract: '',
  coinName: 'Awaiting',
  redirectUrl: '',
  logoUrl: 'https://d1l7ift7wyu6at.cloudfront.net/images/logo/logo-default.png',
  coinDes: '',
  coinStatus: 'Awaiting ads',
  weights: 3,
  stime: null,
  etime: null,
  platform: 'bitchain',
  dataStatus: 1,
  createTime: null,
  updateTime: null,
}
export type ADDataItemType = Partial<typeof defaultADDataItem>

const defaultAdFocus = {
  id: 1,
  contract: '0X1212312',
  coinName: 'PIzza',
  logoUrl: 'https://img.sxsme.com.cn/uploadimg/ico/2021/1230/1640844096882778.jpg\n',
  coinDes: '币描述',
  website: 'https://t.me/KFCCrazyThursdayVme50',
  twitter: 'https://x.com/home',
  telegram: 'https://t.me/KFCCrazyThursdayVme50\n',
  discord: null,
  github: null,
  medium: null,
  reddit: null,
  weights: 0,
  facebook: null,
  whitepaper: null,
  stime: null,
  etime: null,
  platform: 'bitchain',
  dataStatus: 1,
  createTime: null,
  updateTime: null,
}
export type AdFocusType = Partial<typeof defaultAdFocus>

export const CoinStatus = {
  PreSale: 'Pre-sale',
  Listed: 'Listed',
  PreMarket: 'Pre-market',
  Awaiting: 'Awaiting ads',
} as const

export const COIN_COLOR = {
  [CoinStatus.Listed]: '#ED4B9E',
  [CoinStatus.PreSale]: '#E9983D',
  [CoinStatus.Awaiting]: '#31D0AA',
  [CoinStatus.PreMarket]: '#53DEE9',
}
export const COIN_TEST = {
  [CoinStatus.Listed]: 'Listed',
  [CoinStatus.PreSale]: 'PreSale',
  [CoinStatus.Awaiting]: 'Awaiting',
  [CoinStatus.PreMarket]: 'PreMarket',
}
