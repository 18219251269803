import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 12 12" {...props}>
      <path d="M4.762 9.68797L4.91735 7.34105L9.17844 3.50163C9.36708 3.32963 9.1396 3.24641 8.88993 3.39621L3.63015 6.71964L1.35535 5.99836C0.867103 5.85965 0.861554 5.52121 1.46632 5.27708L10.3269 1.85934C10.732 1.67624 11.1203 1.95921 10.965 2.58062L9.45585 9.68797C9.35044 10.1929 9.04528 10.3149 8.62361 10.0819L6.32662 8.38412L5.22251 9.45494C5.0949 9.58256 4.98948 9.68797 4.762 9.68797Z" />
    </Svg>

  );
};

export default Icon;
