import { Text, Heading, CopyAddress, CopyButton, Tag, Row, TelegramIcon, TelegramFilledIcon, useToast, Container, Flex, Box, LogoImg, Column } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { AutoColumn } from 'components/Layout/Column'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import styled from 'styled-components'
import { ADDataItemType, COIN_COLOR, COIN_TEST } from './types'


const StyledBox = styled(Box) <{ isActive: boolean }>`
  width: 70px;
  height: 108px;
  border-radius:  ${({ isActive }) => (isActive ? '4px' : '4px 4px 0 0')};
  border: 1px solid ${({ theme, isActive }) => (!isActive ? 'transparent' : theme.colors.primary)};
  background: ${({ theme, isActive }) => isActive ? theme.colors.backgroundAlt : theme.colors.tertiary};
`

const addressEllipsis = (address: string) =>
  address ? `${address.substring(0, 2)}...${address.substring(address.length - 2)}` : null


export function AdItem({ data, onClick, isActive }: { data: ADDataItemType, onClick: () => void, isActive: boolean }) {
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  return (
    <StyledBox pt='4px' onClick={onClick} isActive={isActive} >
      <AutoColumn gap="4px" style={{ height: '100%', borderRadius: '4px', boxShadow: isActive ? 'none' : '0px 2px 0px 0px rgba(0, 0, 0, 0.10)' }}>
        <LogoImg src={data?.logoUrl} width={40} height={40} style={{ margin: '0 auto', borderRadius: '50%', overflow: 'hidden' }} />
        <Text textAlign='center' px='4px' lineHeight='16px' bold style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', visibility: data?.contract ? 'visible' : 'hidden' }}>{data?.coinName} </Text>
        <RowFixed style={{ margin: '0 auto', position: 'relative', visibility: data?.contract ? 'visible' : 'hidden' }}>
          <Text color="text" fontSize="12px" lineHeight='16px' mr='4px'>
            {addressEllipsis(data?.contract)}
          </Text>
          <CopyButton width="16px" color='text' text={data?.contract} tooltipMessage={t('Copied')} tooltipTop={-40} tooltipFontSize={12} /></RowFixed>
        <Text color={COIN_COLOR[data?.coinStatus]} fontSize="12px" lineHeight='16px' textAlign='center' style={{ letterSpacing: '-0.36px' }} >
          {t(COIN_TEST[data?.coinStatus])}
        </Text>
      </AutoColumn>
    </StyledBox >
  )
}
